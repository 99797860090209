<template>
  <div> {{ fullname }} </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, require: true }
    },
    computed: {
        ...mapGetters({
            clients: 'host/clients'
        }),
        fullname(){
            let client = this.clients.find(item => item.host === this.object.host && item.rootId === parseInt(this.object.client))
            return client ? client.fullname : ''
        }
    }
}
</script>

<style>

</style>